<template>
  <v-card>
    <v-card-title>รายการบันทึก </v-card-title>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="ค้นหา"
        dense
        filled
        rounded
        hide-details
        single-line
        clearable
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="listLogs"
      :search="search"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
      disable-sort
    >
      <template v-slot:no-data>
        <span class="text-center">ยังไม่มีข้อมูล</span>
      </template>
      <template v-slot:no-results>
        <span class="text-center">ไม่พบข้อมูล</span>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        <v-row class="flex-nowrap items-center align-center">
          <div class="pl-2">
            {{ formatDate(item.created_at) }}
          </div>
        </v-row>
      </template>
    </v-data-table>
    <div class="d-flex flex-row-reverse pt-2">
      <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
    </div>
  </v-card>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      listLogs: [],
      headers: [
        { text: 'วันที่', align: 'left', value: 'created_at', width: '15%' },
        { text: 'ข้อความ', align: 'left', value: 'log_text' },
      ],
    }
  },

  mounted() {
    this.getListLogs()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY HH:mm น.')
    },

    getListLogs() {
      this.$store.dispatch('getListLogs').then(res => {
        console.log(res)
        this.listLogs = res
      })
    },
  },
}
</script>
